import React from 'react'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout-sub'

const contactPage = props => {
  const {errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (

    <Layout>
      <SEO title='Contact' />
      <Container>
        <section className='siteHero'>
          <h1 className='siteTitle'>Contact</h1>
          <h2 className='siteSubtitle'>Feel free to reach out and contact.</h2>
        </section>
        <section id='contact'>
          <div className='inner'>
            <section>
    <form
                name="contact"
                method="post"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
    >
                <input type='hidden' name='bot-field' />
                <div className='field-detail'>
                  <div className='field half first'>
                    <label htmlFor='name'>Name</label>
                    <input placeholder='Name' className='feedback-input' type='text' name='name' id='name' required />
                  </div>
                  <div className='field half'>
                    <label htmlFor='email'>Email</label>
                    <input className='feedback-input' type='text' name='email' placeholder='Email' id='email' required />
                  </div>
                </div>

                <div className='field'>
                  <label htmlFor='message'>Message</label>
                  <textarea placeholder='Message' className='feedback-input' name='message' id='message' rows='6' required />
                </div>
                <ul className='actions'>
                  <li><input type='submit' value='Send Message' className='special cta' /></li>
                  <li><input type='reset' value='Clear' className='cta2' /></li>
                </ul>
              </form>
            </section>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default contactPage
